import i18n from '@/lang/index.js'
import { mapState } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapState(['isLogin', 'baseInfo', 'payPwd', 'userInfo'])
    },
    methods: { // 判断是否绑定手机号和支付密码
        judgeMoblieOrPayPwd(val, pop) {
            if (!this.isLogin) {
                this.openLoginPop()
                return
            }
            if (!this.userInfo.mobile) {
                this.$confirm(i18n.t('mixins.index.638921-0'), i18n.t('mixins.index.638921-1'), {
                        confirmButtonText: i18n.t('mixins.index.638921-2'),
                        showCancelButton: false,
                        customClass: "el-pop-wid",
                    })
                    .then(() => {
                        this.$router.push("/userInfo/index");
                    })
                    .catch(() => {
                        console.log(11);
                    });
                return
            }
            if (!this.payPwd) {
                this.$confirm(i18n.t('mixins.index.638921-3'), i18n.t('mixins.index.638921-1'), {
                        confirmButtonText: i18n.t('mixins.index.638921-4'),
                        showCancelButton: false,
                        customClass: "el-pop-wid",
                    })
                    .then(() => {
                        this.$router.push("/userInfo/index");
                    })
                    .catch(() => {
                        console.log(11);
                    });
                return
            }
            if (val) {
                this.$router.push(val)
            }
            if (pop === 1) {
                this.$store.dispatch('setDepositAc', true)
                    // 打开存款
            }
            if (pop === 2) {
                this.$store.dispatch('setTransferAc', true)
                    // 打开转账
            }
            if (pop === 3) {
                this.$store.dispatch('setWithdrawalAc', true)
                    // 打开取款
            }
        },
        openLoginPop() {
            this.$store.dispatch('setIsLoginShowAc', { show: true, type: 'login' })
        },
        openContact(val) {
            window.open(val)
        },
        contactLink() {
            window.open(this.baseInfo[6].configValue)
        },
        openIsLoginUrl() {
            if (!this.isLogin) return
        },
        openUrl(val) {
            this.$router.push(val)
        },
        // 复制
        copyLink(val) {
            let text = val;
            let textarea = document.createElement("textarea");
            textarea.value = text;
            textarea.readOnly = "readOnly";
            document.body.appendChild(textarea);
            textarea.select(); // 选中文本内容
            textarea.setSelectionRange(0, text.length);
            this.$message({
                message: i18n.t('mixins.index.638921-5'),
                type: "success",
            });
            document.execCommand("copy");
            textarea.remove();
        },
        // KOIpay账号正则
        accountKOI(val) {
            const account = /^[k]{1}[b]{1}[A-Za-z0-9]{15,32}$/;
            return !!account.test(val);
        },
        // EBpay账号正则
        accountEB(val) {
            const account = /^[e]{1}[b]{1}[A-Za-z0-9]{15,32}$/;
            return !!account.test(val);
        },
        // USDT账号正则 -TRC20
        accountUSDTTRC(val) {
            const account = /^[T]{1}[A-Za-z0-9]{15,33}$/;
            return !!account.test(val);
        },
        // USDT账号正则 - ERC20
        accountUSDTERC(val) {
            const account = /^[0]{1}[x]{1}[A-Za-z0-9]{15,40}$/;
            return !!account.test(val);
        },
        // 账号脱敏
        accountShow(val) {
            const obj = val.slice(0, 4) + " **** **** " + val.slice(val.length - 4);
            return obj;
        },
        // 手机号码脱敏
        phoneShow(mobile) {
            let reg = mobile.slice(0, 3) + " **** " + mobile.slice(mobile.length - 4);
            return reg;
        },
        // 邮箱脱敏
        emailHide(email) {
            var avg;
            var splitted;
            var email1;
            var email2;
            splitted = email.split("@");
            email1 = splitted[0];
            avg = email1.length / 2;
            email1 = email1.substring(0, email1.length - avg);
            email2 = splitted[1];
            return email1 + "***@" + email2; // 输出为11223***@qq.com
        },
        // 是否开启短信
        openSms() {
            this.$Api.getSysConfigValue({
                key: 'sms_switch'
            }).then(res => {
                const tagetVal = res.sysConfigValue === 'off' ? true : false;
                this.$store.dispatch('setSmsSwitchAc', tagetVal);
            });
        },
        // 校验Email
        // checkEmail(val) {
        //     let gsMail = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        //     if (!gsMail.test(val)) {
        //         this.$message({
        //             message: '请填写正确的邮箱格式',
        //             type: 'warning'
        //         })
        //         return false;
        //     }
        //     return true
        // },
        // 刷新个人资料接口
        refreshUserInfo() {
            this.$Api.getUserInfo().then(res => {
                if (res) {
                    this.$store.dispatch('setUserInfoAc', res.userInfo)
                }
            })
        },
        // 刷新支付密码
        refreshPassword() {
            this.$Api.checkIsSetWidthPassword().then((res) => {
                if (res) {
                    const val = res.msg === "yes" ? true : false;
                    this.$store.dispatch("setPayPwdAc", val);
                }
            });
        },
        // 打开存款
        openDeposit() {
            this.$store.dispatch('setDepositAc', true)
        },
        // 暂未开放
        unopened() {
            this.$message({
                message: i18n.t('mixins.index.638921-6'),
                type: 'warning',
            })
        },
    },
}