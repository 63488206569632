import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang_zh from '@/lang/zh.json'
import lang_pt from '@/lang/pt.json'
import lang_md from '@/lang/md.json'
import lang_th from '@/lang/th.json'
import lang_en from '@/lang/en.json'
import lang_bi from '@/lang/bi.json'
import lang_hk from '@/lang/hk.json'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'hk',
    messages: {
        zh: {...lang_zh },
        pg: {...lang_pt },
        md: {...lang_md },
        th: {...lang_th },
        en: {...lang_en },
        bi: {...lang_bi },
        hk: {...lang_hk },
    }
})
export default i18n