<template>
  <div class="jf-mode-flex">
    <div class="user-box">
      <div class="user-box-title">{{ $t("components.mode.553586-0") }}</div>
      <div class="pay-list">
        <ul v-if="paysList">
          <li
            v-for="(item, index) in paysList"
            :key="index"
            :class="{ 'hover-target': index === paysInd }"
            @click="changePay(item, index)"
          >
            <img v-lazy="item.iconUrl" class="img" />
            <span class="name">{{ item.name }}</span>
            <p v-if="item.extraRate" class="gift">
              {{ $t("components.mode.553586-1") }}{{ item.extraRate }}%
            </p>
            <span
              class="hots sjf-recommended-fill"
              v-if="item.isRecommend === 1"
            ></span>
          </li>
        </ul>
      </div>
    </div>
    <template
      v-if="
        paysList &&
        [4, 13].includes(paysList[paysInd].paymentMethodType) &&
        paysList[paysInd].coinType === 'USDT'
      "
    >
      <!-- {{ $t('components.mode.553586-2') }} -->
      <div class="user-box">
        <div class="user-box-title">{{ $t("components.mode.553586-2") }}</div>
        <ul class="usdt-type-list">
          <li class="hover-target">USDT</li>
        </ul>
      </div>
      <!-- 虚拟币协议 -->
      <div class="user-box">
        <div class="user-box-title">
          {{ $t("components.mode.553586-3")
          }}<el-tooltip class="item" effect="dark" placement="bottom-end">
            <div slot="content">
              <span>OMNI：</span>{{ $t("components.mode.553586-4") }}<br /><span
                >ERC20：</span
              >{{ $t("components.mode.553586-5") }}<br /><span>TRC20：</span
              >{{ $t("components.mode.553586-6") }}
            </div>
            <div class="sec">{{ $t("components.mode.553586-7") }}</div>
          </el-tooltip>
        </div>
        <ul class="usdt-type-list">
          <li
            v-for="(item, index) in paysList[paysInd].networkType"
            :key="index"
            :class="{ 'hover-target': index === networkIndex }"
            @click="networkTab(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </template>
    <!-- 填写存款人 -->
    <div
      class="user-box"
      v-if="paysList && paysList[paysInd].paymentMethodType === 1"
    >
      <div class="user-box-title">{{ $t("components.mode.553586-8") }}</div>
      <div class="user-pay-name">
        <input
          type="text"
          class="pay-input"
          v-model="from.name"
          :placeholder="$t('components.mode.553586-9')"
          @input="changeFrom"
        />
        <p class="pay-gray-tips">{{ $t("components.mode.553586-10") }}</p>
      </div>
    </div>
    <!-- {{ $t('deposit.stepMode.598561-18') }} -->
    <div class="user-box" v-if="from.type !== 101">
      <div class="user-box-title">{{ $t("components.mode.553586-11") }}</div>
      <div>
        <ul
          class="user-pay-money"
          v-if="paysList && paysList[paysInd].quickAmount"
        >
          <li
            v-for="(item, index) in paysList[paysInd].quickAmount"
            :key="index"
            :class="{ 'hover-target': index === moneyInd }"
            @click="selectMoney(item, index)"
          >
            {{ item }}
          </li>
        </ul>
        <template v-if="paysList && paysList[paysInd].amountType !== 2">
          <input
            v-if="paysList && paysList[paysInd].quickAmount.length"
            type="text"
            v-model="from.money"
            class="pay-input"
            :placeholder="
              $t('components.mode.553586-12') +
              paysList[paysInd].quickAmount[0] +
              '-' +
              paysList[paysInd].quickAmount[
                paysList[paysInd].quickAmount.length - 1
              ] +
              $t('components.mode.553586-13')
            "
            maxlength="5"
            @input="changeFrom"
          />
          <input
            type="text"
            v-model="from.money"
            class="pay-input"
            v-else
            :placeholder="$t('components.mode.553586-14')"
            maxlength="5"
            @input="changeFrom"
          />
        </template>
        <br />
        <p
          class="pay-gray-tips"
          v-if="
            paysList && [2, 3].includes(paysList[paysInd].paymentMethodType)
          "
        >
          {{ $t("components.mode.553586-15") }}
        </p>
        <p
          class="pay-gray-tips"
          v-if="
            paysList &&
            paysList[paysInd].coinType === 'USDT' &&
            sysDictionaryList
          "
          style="line-height: 24px"
        >
          {{ $t("components.mode.553586-16")
          }}<span class="text-blue">{{
            from.money
              ? (from.money / sysDictionaryList.value).toFixed(4)
              : "0.000"
          }}</span>
          {{ sysDictionaryList.name }} 1USDT ≈
          {{ sysDictionaryList.value }}CNY<br />
          {{ $t("components.mode.553586-17") }}
        </p>
        <!-- 101 -->
        <p
          class="pay-gray-tips"
          v-if="
            paysList &&
            paysList[paysInd].paymentMethodType === 118 &&
            exchangerate
          "
          style="line-height: 24px"
        >
          <!-- paymentMethodType -->
          {{ $t("components.mode.553586-16")
          }}<span class="text-blue">{{
            from.money ? getMoney118 : "0.00"
          }}</span>
          {{ exchangerate.name }}{{ exchangerate.value }}<br />
          {{ $t("components.mode.553586-17") }}
        </p>
        <p v-if="paysList && paysList[paysInd].remark" class="pay-gray-tips">
          {{ paysList[paysInd].remark }}
        </p>
        <el-button
          class="btn-wid400"
          type="primary"
          :disabled="isFillOver"
          @click="getRechargeAccountInfo"
          >{{ $t("components.mode.553586-18") }}</el-button
        >
        <p class="pay-red-tips">
          {{ $t("components.mode.553586-19") }}
        </p>
        <img src="@/assets/pic/ad01.webp" class="jf-dep-img-tip" />
      </div>
    </div>
    <div v-else>
      <el-button
        class="btn-wid400"
        type="primary"
        @click="openContact(baseInfo[6].configValue)"
        >{{ $t("components.mode.553586-20") }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      from: {
        type: "", //取款方式
        name: "", //人名
        typeName: "", //取款名称
        coinType: "USDT", //虚拟名称
        networkType: "", //虚拟支付类型
        money: undefined, //金额
        id: "",
        address: "",
      },
      moneyInd: undefined, //金额索引值
      paysList: null, //存款方式
      paysInd: 0, //存款方式索引
      isFillOver: true, //是否开启提交按钮
      networkIndex: 0, //虚拟币协议
      sysDictionaryList: null, //汇入兑换率
      orderInfo: "",
      exchangerate: null,
    };
  },
  computed: {
    getMoney118() {
      return (
        this.from.money * Number(this.exchangerate.value) +
        Number(Math.random().toFixed(2))
      );
    },
  },
  mounted() {
    this.getPaymentMethod();
    this.getSysDictionary();
    this.getExchangerate();
  },
  methods: {
    // 当前虚拟币兑换数值
    getSysDictionary() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "usdt_deposit_exchange_rate" })
        .then((res) => {
          if (res) {
            this.sysDictionaryList = res.sysDictionaryList[0];
          }
        });
    },
    // 101
    getExchangerate() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "other_deposit_exchange_rate" })
        .then((res) => {
          if (res) {
            this.exchangerate = res.sysDictionaryList[0];
          }
        });
    },
    // 切换重置存款方式
    resetFrom() {
      this.from = {
        type: "", //取款方式
        typeName: "", //取款方式名称
        name: "", //人名
        coinType: "USDT", //虚拟名称
        networkType: "", //虚拟支付类型
        money: undefined, //金额
        address: "",
      };
    },
    // 触发检验from值
    changeFrom() {
      const val = this.checkFrom();
      this.isFillOver = val;
    },
    // 校验From
    checkFrom() {
      const { type, name, networkType, money, coinType } = this.from;
      if (!money) {
        this.moneyInd = undefined;
      }
      // 转卡校验
      if (money && name && type === 1) {
        return false;
      }
      // 虚拟币
      if (money && [4].includes(type) && networkType && coinType) {
        return false;
      }
      // 第三方
      if (money && ![1, 4].includes(type)) {
        return false;
      }
      return true;
    },
    // 选择存款金额
    selectMoney(val, index) {
      this.from.money = val;
      this.moneyInd = index;
      this.changeFrom();
    },
    // 获取存款方式
    getPaymentMethod() {
      this.$Api.getPaymentMethod().then((res) => {
        if (res) {
          res.paymentMethodList.forEach((item) => {
            if (item.quickAmount) {
              item.quickAmount = item.quickAmount.split(",");
            }
            if (item.networkType) {
              item.networkType = item.networkType.split(",");
            }
          });
          this.from.type = res.paymentMethodList[0].paymentMethodType; //默认存款方式
          this.from.id = res.paymentMethodList[0].id;
          this.from.typeName = res.paymentMethodList[0].name;
          this.paysList = res.paymentMethodList;
          this.from.coinType = res.paymentMethodList[0].coinType;
          this.from.networkType = res.paymentMethodList[0].networkType
            ? res.paymentMethodList[0].networkType[0]
            : "";
        }
      });
    },
    // 选择存款方式
    changePay(val, index) {
      this.resetFrom();
      this.paysInd = index;
      this.typeName = val.name;
      this.moneyInd = undefined; //重置选金额
      this.from.type = val.paymentMethodType;
      this.from.id = val.id;
      this.from.typeName = val.name;

      // 虚拟币情况
      this.from.coinType = val.coinType;
      this.from.networkType =
        val.coinType === "USDT"
          ? this.paysList[this.paysInd].networkType[this.networkIndex]
          : "";
      this.changeFrom();
    },

    // 虚拟币切换协议
    networkTab(val, index) {
      this.networkIndex = index;
      this.from.networkType = val;
    },
    getRechargeAccountInfo() {
      const { type, networkType, coinType } = this.from;
      let transferType = "";
      switch (type) {
        case 1:
          transferType = "bankCard";
          break;
        case 5:
        case 4:
          transferType = "blockchain";
          break;
        default:
          transferType = "otherPay";
      }
      let params = {
        device: 1,
        rechargeType: transferType,
        paymentMethodId: type,
      };
      if (type === 4) {
        params.coinType = coinType;
        params.networkType = networkType;
      }
      this.$Api.getRechargeAccountInfo(params).then((res) => {
        if (res) {
          if (res.rechargeAccountInfo) {
            const { bankcardNo, address } = res.rechargeAccountInfo;

            this.from.address = bankcardNo || address;
          }
          this.getRecharge();
        }
      });
    },
    // 提交存款
    getRecharge() {
      if (
        [
          13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
          30, 31, 32, 33, 34, 35, 36, 37, 44, 45,
        ].includes(this.from.type)
      ) {
        this.selectPost();
        return;
      }
      this.getRechargeBank();
    },
    // 第三方接口
    selectPost() {
      // 接口参数
      const { type, money, coinType, networkType, id, address } = this.from;
      const params = {
        rechargeMoney: money,
        paymentMethodId: id,
        rechargeAccount: address,
      };
      if (coinType) {
        params.coinType = "USDT";
        params.networkType = networkType;
      }
      // 选择接口
      const threePost = [
        { type: 13, post: this.$Api.JYpayCreateOrder },
        { type: 14, post: this.$Api.getCenterCreateOrder },
        { type: 15, post: this.$Api.huaLianPayCreateOrder },
        { type: 16, post: this.$Api.toPaycreateOrder },
        { type: 17, post: this.$Api.hiWalletCreateOrder },
        { type: 18, post: this.$Api.bnPaycreateOrder },
        { type: 19, post: this.$Api.hiPaycreateOrder },
        { type: 20, post: this.$Api.juHePaycreateOrder },
        { type: 21, post: this.$Api.xcbfPaycreateOrder },
        { type: 22, post: this.$Api.jPayCreateOrder },
        { type: 23, post: this.$Api.baiHePayCreateOrder },
        { type: 29, post: this.$Api.kdPayCreateOrder },
        { type: 24, post: this.$Api.goPayCreateOrder },
        { type: 44, post: this.$Api.noPayCreateOrder },
        { type: 45, post: this.$Api.hfPayCreateOrder },
      ];
      let postUrl = threePost.find((item) => item.type === type).post;
      postUrl(params).then((res) => {
        if (res) {
          this.orderInfo = {
            mode: 2,
            type: type,
            money: money,
            orderNo: res.orderNo,
          };
          this.$emit("update", this.orderInfo, 3);
          if (type === 19) {
            localStorage.removeItem("html");
            localStorage.setItem("html", res.payUrl);
            const newWin = window.open("", "_blank");
            newWin.document.write(localStorage.getItem("html", res.payUrl));
            newWin.document.close();
            return;
          }
          window.open(res.payUrl);
        }
      });
    },
    // 快速充值
    getRechargeBank() {
      const {
        type,
        typeName,
        name,
        networkType,
        money,
        id,
        coinType,
        address,
      } = this.from;
      const params = {
        coinType: networkType ? "USDT" : "", //USDT
        networkType, //虚拟协议
        rechargeMoney: money, //存款金额
        rechargeUserName: name, //存款人名
        paymentMethodId: id,
        rechargeAccount: address,
      };
      this.$Api.getRecharge(params).then((res) => {
        if (res) {
          const valList = [
            { type: 1, number: "bankCard" },
            { type: 4, number: "blockchain" },
          ];
          const modeType = valList.find((item) => item.type === type);
          let typeVal = "";
          if (modeType) {
            typeVal = modeType.number;
          } else {
            typeVal = "otherPay";
          }
          let money118 = "";
          if (id === 118 || type === 118) {
            money118 = this.getMoney118;
          }
          this.orderInfo = {
            mode: 1,
            type: type,
            money: money118 || money,
            orderNo: res.rechargeId,
            rechargeType: typeVal,
            id: id,
            coinType: type === 4 ? coinType : "",
            networkType: type === 4 ? networkType : "",
            usdt: (money / this.sysDictionaryList.value).toFixed(4),
          };
          this.$emit("update", this.orderInfo, 2);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-box {
  display: flex;
  flex-direction: column;
  gap: 0.16rem;
}
.pay-list {
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
  }

  li {
    position: relative;
    height: 80px;
    background: #17181b;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    cursor: pointer;
    gap: 0.06rem;

    &.hover-target {
      background: #009d81;
      color: white;
    }

    .img {
      width: 32px;
      height: 32px;
    }

    .name {
      font-family: PingFang SC;
      line-height: 1;
      max-width: 120px;
      overflow: hidden;
      font-size: 12px;
    }

    .hots {
      font-size: 28px;
      position: absolute;
      right: -6px;
      top: -6px;
      color: var(--color-theme);
    }

    .gift {
      font-size: 12px;
      height: 20px;
      min-width: 50px;
      white-space: nowrap;
      overflow: hidden;
      color: white;
      background: red;
      border-radius: 10px;
      font-family: PingFang SC;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
    }
  }
}

.pay-input {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #17181b;
  padding: 0 0.14rem;
  outline: none;
  color: white;
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--text-1);
    font-size: 14px;
  }
}

.pay-gray-tips,
.pay-red-tips {
  min-height: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 12px 0;
}

.pay-red-tips {
  color: #e95b5b;
  margin-top: 12px;
}

.user-pay-money {
  width: 100%;
  margin: 0 0 8px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;

  li {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 0;
    border: 0.01px solid #494949;
    &.hover-target {
      border: 0.01px solid #009d81;
    }
  }
}

// 虚拟充值步骤
.usdt-step {
  border-radius: 12px;
  overflow: hidden;
  padding: 16px 24px;
  flex: none;

  strong {
    font-weight: normal;
    font-size: 14px;
    color: #179cff;
    margin-bottom: 10px;
    display: block;
  }

  ul {
    width: 580px;
    height: 32px;
    background-size: cover;
    background-size: contain;
    display: flex;
    align-items: center;

    li {
      color: #179cff;
      font-size: 12px;
      width: 140px;
      text-align: center;
    }
  }
}

.usdt-type-list {
  display: flex;
  align-items: center;

  li {
    width: 166px;
    height: 40px;
    border-radius: 5px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-right: 16px;
    border: 0.01px solid #494949;
    &.hover-target {
      border: 0.01px solid #009d81;
    }
  }
}

.three-load {
  width: 320px;
  height: 61px;
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative;
  background-size: cover;

  img {
    width: 30px;
    margin-right: 6px;
  }

  .sec {
    flex: 1;

    span {
      font-weight: 500;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      color: #303442;
    }

    p {
      height: 21px;
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      padding: 0 8px;
      background: rgba(23, 156, 255, 0.15);
      color: #179cff;
      border-radius: 19px;
      display: inline-block;
    }
  }

  .load {
    cursor: pointer;
    width: 64px;
    height: 24px;
    background: #179cff;
    border-radius: 4px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }
}

.user-box-title {
  justify-content: space-between;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sec {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: var(--text-1);
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 2px;
    }
  }
}

.btn-wid400 {
  margin-top: 20px;
}
.jf-mode-flex {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
/deep/.btn-wid400 {
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  &.is-disabled {
    color: rgba(255, 255, 255, 0.5);
    background: #0e5f51 !important;
    border: 1px solid #0e5f51 !important;
  }
}
.jf-dep-img-tip {
  width: 80%;
  margin: 0 auto;
  display: block;
}
</style>
