<template>
  <section :class="{ shrink: isShrink }">
    <div class="logo">
      <div class="icon-menu menu" @click="openShrink"></div>
      <img src="@/assets/pic/logo.webp" class="img" />
    </div>
    <div class="scroll">
      <!-- 快捷入口 -->
      <div class="jf-nav" v-if="!isShrink">
        <div class="row" @click="contactLink">
          <img src="@/assets/pic/nav04.png" />
        </div>
        <div class="row" @click="openUrl('/invite')">
          <img src="@/assets/pic/nav03.png" />
        </div>
        <div class="row" @click="openUrl('/download')">
          <img src="@/assets/pic/nav02.png" />
        </div>
        <div class="row" @click="judgeMoblieOrPayPwd()">
          <img src="@/assets/pic/nav01.png" />
        </div>
      </div>
      <!-- 辅助栏目 -->
      <div class="jf-menu">
        <div v-if="navsList">
          <ul>
            <li
              v-for="(item, index) in navsList"
              :key="index"
              @click="openUrl(item.link)"
            >
              <span class="icon" :class="item.icon"></span>
              <span class="text">{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <div v-for="(item, index) in navs" :key="index">
          <ul>
            <li
              v-for="(row, rowIndex) in item"
              :key="rowIndex"
              @click="openJumpLink(row)"
            >
              <span
                class="icon"
                :class="[
                  row.icon,
                  { 'text-grenn': [0, 3].includes(index) && rowIndex === 0 },
                ]"
              ></span>
              <span class="text">{{ row.name }}</span>
            </li>
          </ul>
          <div class="jf-menu-live" v-if="index === 2 && !isShrink">
            <a
              href="javascript:void(0)"
              @click="openContact(baseInfo[11].configValue)"
            >
              <img src="@/assets/pic/live02.webp" />
            </a>
            <a
              href="javascript:void(0)"
              @click="openContact(baseInfo[12].configValue)"
            >
              <img src="@/assets/pic/live05.webp" />
            </a>
            <a
              href="javascript:void(0)"
              @click="openContact(baseInfo[13].configValue)"
            >
              <img src="@/assets/pic/live04.webp" />
            </a>
            <a
              href="javascript:void(0)"
              @click="callMobile(baseInfo[14].configValue)"
            >
              <img src="@/assets/pic/live03.webp" />
            </a>
          </div>
        </div>
        <img src="@/assets/pic/ad01.webp" v-if="!isShrink" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      navs: [
        [
          {
            icon: "icon-share3",
            name: this.$t("components.Left.964133-0"),
            link: "/invite",
          },
          // {
          //   icon: "icon-vip",
          //   name: "现金返还",
          //   link: "",
          // },
          {
            icon: "icon-gift",
            name: this.$t("components.Left.964133-1"),
            link: "/promotion",
          },
          {
            icon: "icon-help",
            name: this.$t("components.Left.964133-2"),
            link: "/help",
          },
        ],
        [
          {
            icon: "icon-purse",
            name: this.$t("components.Left.964133-3"),
            link: "",
            type: 1, //打开弹层
          },
          {
            icon: "icon-round-transfer",
            name: this.$t("components.Left.964133-4"),
            link: "",
            type: 2, //打开弹层
          },
          {
            icon: "icon-card",
            name: this.$t("components.Left.964133-5"),
            link: "",
            type: 3, //打开弹层
          },
        ],
        [
          {
            icon: "icon-dice",
            name: this.$t("components.Left.964133-6"),
            link: "/download",
          },
        ],
        [
          {
            icon: "icon-earphone",
            name: this.$t("components.Left.964133-7"),
            contact: true,
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState(["navsList", "isShrink"]),
  },
  methods: {
    callMobile(val) {
      var phoneLink = "tel:" + val;
      var link = document.createElement("a");
      link.href = phoneLink;
      link.click();
    },
    openShrink() {
      if (this.isShrink) {
        this.$store.dispatch("setShrinkAc", false);
      } else {
        this.$store.dispatch("setShrinkAc", true);
      }
    },
    openJumpLink(val) {
      if (val.contact) {
        this.openContact(this.baseInfo[6].configValue);
        return;
      }
      if (val.type === 1) {
        this.judgeMoblieOrPayPwd(false, 1);
        return;
      }
      if (val.type === 2) {
        if (this.isLogin) {
          this.$store.dispatch("setTransferAc", true);
        } else {
          this.openLoginPop();
        }
        return;
      }
      if (val.type === 3) {
        this.judgeMoblieOrPayPwd(false, 3);
        return;
      }
      if (val.link) {
        this.openUrl(val.link);
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$shrink: 0.56rem;
.shrink {
  width: $shrink !important;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    .menu {
      margin-right: 0;
    }
    .img {
      display: none;
    }
  }
  .scroll {
    padding: 0;
  }
  .jf-menu {
    border-top: 1px solid #25272c;
    margin-top: 0;
    gap: 0;
    div {
      &:nth-child(2) {
        display: none;
      }
    }
    ul {
      background: none;
      gap: 0.04rem;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #25272c;
      padding: 0.1rem 0;
      li {
        width: $shrink;
        .icon {
          font-size: 0.19rem;
          margin: 0 auto;
        }
        .text {
          display: none;
        }
      }
    }
  }
}
.logo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 0.64rem;
  background: var(--bg);
  .menu {
    margin-right: 0.66rem;
    font-size: 0.2rem;
    cursor: pointer;
  }
  .img {
    height: 0.26rem;
  }
}
.scroll {
  padding: 0.05rem 0.1rem;
  flex: 1;
  overflow-y: auto;
}
.jf-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.08rem;
  margin-top: 0.06rem;
  .row {
    border-radius: 0.04rem;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
.jf-menu {
  display: flex;
  flex-direction: column;
  gap: 0.16rem;
  margin-top: 0.2rem;
  ul {
    background: var(--bg-1);
    li {
      height: 0.42rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        font-size: 0.18rem;
        margin: 0 0.22rem 0 0.12rem;
      }
      .text {
        font-size: 0.13rem;
      }
    }
  }
  &-live {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.08rem;
    background: var(--bg-1);
    margin-top: 0.16rem;
    padding: 0.06rem 0.1rem;
    img {
      height: 0.3rem;
      width: 0.3rem;
    }
  }
}
</style>
